import "../scss/app.scss";

$(function () {

  $(document).ready(function() {
    const url = location.pathname;
    const links = $('.js-nav_link');
    $.each(links, function(i, x) {
      // console.log($(x).attr('href'), url.replace(/.php/, ''));
      if(url.indexOf($(x).attr('href')) > -1) {
        $(x).parent().addClass('nav_active');
      }
      // console.log($(x).attr('href'));
    });
    // console.log(url);
  });

  /**
   * 郵便番号入力で住所補完
   * @return {[type]} [description]
   */
  $('.js-zip_search').on('keyup', function() {
    AjaxZip3.zip2addr('T_ZIP','','T_KEN','T_CITY');
    //成功時に実行する処理
    AjaxZip3.onSuccess = function() {
        $('.T_KEN').focus();
        $('.T_CITY').focus();
    };

    //失敗時に実行する処理
    AjaxZip3.onFailure = function() {
        alert('郵便番号に該当する住所が見つかりません');
    };

    return false;
  });


  /**
   * クリック時に履歴表示
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  $('.js-history_btn').on('click', function(e) {
    const target = $(e.currentTarget);
    target.find('.dltable_history').toggleClass('is-history_open');
  });

  /**
   * レスポンシブ時のメニューバー
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  $('.js-menu').on('click', function(e) {
    const target = $(e.currentTarget);
    // console.log(target.toggleClass('menu_toggle'));
    target.toggleClass('menu_toggle')
    // target.find('.js-menu').toggle('menu_toggle');
  });

  $('.js-customer_id').on('blur', function(e) {
    const mail = $('.js-reset_email');
    const customer = $('.js-customer_id');
    $('.error').remove();

    if($(customer).val() == "") {
      $(customer).after('<p class="error">必須項目です。</p>');
      $('.js-reset_submit').prop('disabled', true);
    }

    // if($(mail).val() == "") {
    //   $(mail).after('<p class="error">必須項目です。</p>');
    //   $('.js-reset_submit').prop('disabled', true);
    // }
  });

  $('.js-reset_email').on('blur', function(e) {
    const mail = $('.js-reset_email');
    const customer = $('.js-customer_id');
    $('.error').remove();

    if($(mail).val() != "") {
      if(!$(mail).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
        $(mail).after('<p class="error">メールアドレスに誤りがあります。</p>');
        $('.js-reset_submit').prop('disabled', true);
      } else if(customer.val() == "") {
        $(customer).after('<p class="error">必須項目です。</p>');
        $('.js-reset_submit').prop('disabled', true);
      } else {
        $('.js-reset_submit').prop('disabled', false);
      }
    } else {
      $(mail).after('<p class="error">必須項目です。</p>');
      $('.js-reset_submit').prop('disabled', true);

    }
  });

  $('.js-new_password_confirm').on('blur', function(e) {
    const target = $(e.currentTarget);
    const pass = $('.js-new_password').val();
    const confirm = target.val();
    $('.error').remove();
    if(confirm != "") {
      if(pass != confirm) {
        $('.js-new_password_confirm').after('<p class="error">パスワードに誤りがあります。</p>');
        $('.js-reset_submit').prop('disabled', true);
      } else {
        $('.js-reset_submit').prop('disabled', false);
      }
    } else {
      $(target).after('<p class="error">必須項目です。</p>');
    }
  });

  $('.js-new_password_confirm').on('submit', function() {
    const pass = $('.js-new_password').val();
    const confirm = $('.js-new_password_confirm').val();

    if(pass == "" && confirm == "" && pass === confirm) {
      return true;
    } else {
      return false;
    }
  });
});
